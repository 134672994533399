.logo {
  max-width: 256px;
}

.user-info-loggedin img {
  margin-right: .4em;
  max-width: 32px;
  max-height: 32px;
}

.user-info-loggedin.dropdown-toggle::after {
  margin-left: .455em;
}

.modal-banned .modal-content {
  border-color: #d41111;
}

.empty-state-message img {
  margin-bottom: 1.5em;  
}

.user-avatar {
  display: inline-block;
}

.user-icon-mod, .user-icon-admin, .user-icon-poweruser {
  position: absolute;

  .user-icon {
    font-size: 16px;
    position: absolute;
    margin-top: -12px;
    margin-left: 19px;
    color: #73c322;
  }

  .user-icon-bg {
    font-size: 20px;
    position: absolute;
    color: white;
    margin-top: -14px;
    margin-left: 17px;
  }
}

.user-icon-admin .user-icon {
  color: #d41111;
}

.user-icon-poweruser .user-icon {
  color: #901cfd;
}

.user-info.dropdown .dropdown-menu {
  margin-top: 5px !important;
}

.footer {
  background-color: #253858;
  color: #ebecf0;

  a {
    color: #8cd1f7;
  }
}